import { Avatar, Col, DatePicker, Form, Row, Table, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Container } from "components/core";
import { InputDropDown } from "components/Inputs";
import dayjs from "dayjs";
import { useState } from "react";
import type { ColumnsType, ColumnType } from "antd/es/table";
import styled from "styled-components";
import Icon from "components/Icon";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { CalendarDataType, CalendarResponse, DaysInMonth } from "model/Calendar";
import COLORS from "constants/colors";
import { useDepartments, useLocations, useWindowInfiniteScroll } from "hooks";
import TABLE from "constants/table";
import DATE_FORMAT from "constants/dateFormat";
import { useQuery } from "@apollo/client";
import { FETCH_CALENDAR_DATA } from "services/graphql/calendar";
import { PageInfo } from "model/Common";
import { isToday } from "utils";
import { LeaveRequestsStatus } from "model/Leave";
import TodayDrawer from "./TodayDrawer";
import CalendarCell from "./CalendarCell";

const Calendar = () => {
  const [form] = useForm();
  const [open, setOpen] = useState(false);
  const [data, setCalendarData] = useState<CalendarDataType[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [after, setAfter] = useState<string>();
  const [dateFilterForm] = useForm();
  const currentDate = Form.useWatch("date", dateFilterForm);
  const {
    departments,
    loading: departmentsLoading,
    handleDropdownScroll: handleDepartmentDropdownScroll,
  } = useDepartments({});
  const { locations, loading: locationsLoading, handleDropdownScroll: handleLocationDropdownScroll } = useLocations({});
  const selectedDepartments = Form.useWatch("departments", form);
  const selectedLocations = Form.useWatch("locations", form);

  const { loading } = useQuery<CalendarResponse>(FETCH_CALENDAR_DATA, {
    variables: {
      first: TABLE.rowsPerPage,
      after,
      departmentIds: selectedDepartments,
      locationIds: selectedLocations,
      calendarYear: dayjs(currentDate).year(),
      month: dayjs(currentDate).month() + 1,
      statuses: [LeaveRequestsStatus.Approved, LeaveRequestsStatus.Pending],
    },
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      setCalendarData((d) => [...d, ...(response.fetchCalendarData.nodes ?? [])]);
      if (response.fetchCalendarData.pageInfo) setPageInfo(response.fetchCalendarData.pageInfo);
    },
  });

  const getDays = () => {
    const daysInMonth: DaysInMonth[] = [];
    const monthDate = dayjs(currentDate).startOf("month");
    for (let i = 0; i < monthDate.daysInMonth(); i += 1) {
      const newDay = monthDate.clone().add(i, "day");
      daysInMonth.push({
        date: newDay.format(DATE_FORMAT.dayOfMonth),
        day: newDay.format(DATE_FORMAT.dayOfWeek).split("")[0],
        isToday: isToday(i, currentDate),
      });
    }
    return daysInMonth;
  };

  const resetCalendar = () => {
    setCalendarData([]);
    setPageInfo(undefined);
    setAfter(undefined);
  };

  useWindowInfiniteScroll({
    hasNextPage: pageInfo?.hasNextPage,
    endCursor: pageInfo?.endCursor,
    loading,
    callback: () => {
      setAfter(pageInfo?.endCursor);
    },
  });

  const prevAndNextMonthHandler = (isNext: boolean) => {
    dateFilterForm.setFieldValue("date", currentDate.add(isNext ? 1 : -1, "month"));
    resetCalendar();
  };

  const todayButtonHandler = () => {
    if (currentDate.year() !== dayjs().year() || currentDate.month() !== dayjs().month()) {
      dateFilterForm.setFieldValue("date", dayjs());
      resetCalendar();
    }
    setOpen(true);
  };

  const columns: ColumnsType<CalendarDataType> = [
    {
      title: () => (
        <Form
          form={dateFilterForm}
          initialValues={{ date: dayjs() }}
          onValuesChange={() => {
            resetCalendar();
          }}
        >
          <StyledDatePicker>
            <StyledIcon
              name="left"
              data-testid="prevMonth"
              onClick={() => prevAndNextMonthHandler(false)}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.key === "Enter") {
                  prevAndNextMonthHandler(false);
                }
              }}
              role="button"
              tabIndex={0}
            />
            <Form.Item name="date" className="m-0">
              <DatePicker
                format={DATE_FORMAT.monthAndYear}
                picker="month"
                changeOnBlur
                bordered={false}
                suffixIcon={false}
                allowClear={false}
                className="p-1"
              />
            </Form.Item>
            <StyledIcon
              name="right"
              data-testid="nextMonth"
              onClick={() => prevAndNextMonthHandler(true)}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.key === "Enter") {
                  prevAndNextMonthHandler(true);
                }
              }}
              role="button"
              tabIndex={0}
            />
          </StyledDatePicker>
        </Form>
      ),
      dataIndex: "name",
      key: "name",
      className: "employee-column",
      width: 300,
      align: "left",
      fixed: "left",
      render: (_, { name, jobTitle, profileImageUrl }) => (
        <div className="d-flex gap-4 align-items-center">
          <StyledAvatar src={profileImageUrl} size={40} icon={<Icon name="userFilled" />} alt="avatar" />
          <div>
            <StyledUserName className="m-0" level={2}>
              {name}
            </StyledUserName>
            <StyledText>{jobTitle?.name ?? "-"}</StyledText>
          </div>
        </div>
      ),
    },
    {
      width: 25,
    },
    ...getDays().map(
      (e, index, array): ColumnType<CalendarDataType> => ({
        title: () => (
          <StyledDayHeader>
            {e.isToday && (
              <Typography.Title level={3} className="todayTitle">
                Today
              </Typography.Title>
            )}
            <span className={`${e.isToday ? "todayDay" : ""}`}>{e.day}</span>
          </StyledDayHeader>
        ),
        dataIndex: "date",
        className: "date",
        align: "center",
        width: "100%",
        onCell: () => ({
          colSpan: index === 0 ? array.length : 0,
        }),
        render: (_, { leaveRequests, workingCondition, publicHolidays, id }) => (
          <CalendarCell
            leave={leaveRequests}
            dateNo={getDays().map((e) => e.date)}
            currentDate={currentDate}
            currentWorkingCondition={workingCondition}
            userId={id}
            publicHolidays={publicHolidays}
          />
        ),
      }),
    ),
    {
      width: 25,
    },
  ];

  return (
    <div className="h-100" id="main">
      <StyledTitleSection id="calendar_header">
        <Typography.Title className="m-0">Calendar</Typography.Title>
        <div className="d-flex justify-content-end align-items-center">
          <StyledLegend className="holiday">Holiday</StyledLegend>
          <StyledLegend className="otherleave">Other Leave</StyledLegend>
          <StyledLegend className="dayOff">Non working days</StyledLegend>
        </div>
      </StyledTitleSection>
      <Container className="m-0 pb-2 pt-2 section-box-shadow" id="calendar_filter">
        <Form
          form={form}
          colon={false}
          onValuesChange={() => {
            setCalendarData([]);
            setPageInfo(undefined);
            setAfter(undefined);
          }}
        >
          <Row gutter={32} className="d-flex align-items-center">
            <Col lg={10} xl={8}>
              <Form.Item label="Location" name="locations" className="py-2 m-0">
                <InputDropDown
                  placeholder="Please select"
                  options={locations}
                  loading={locationsLoading}
                  mode="multiple"
                  maxTagCount="responsive"
                  data-testid="locations"
                  showSearch={false}
                  selectedValue={selectedLocations}
                  optionLabelProp="label"
                  onPopupScroll={handleLocationDropdownScroll}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            </Col>
            <Col lg={10} xl={8}>
              <Form.Item label="Department" name="departments" className="py-2 m-0">
                <InputDropDown
                  placeholder="Please select"
                  options={departments}
                  loading={departmentsLoading}
                  mode="multiple"
                  maxTagCount="responsive"
                  data-testid="departments"
                  showSearch={false}
                  selectedValue={selectedDepartments}
                  optionLabelProp="label"
                  onPopupScroll={handleDepartmentDropdownScroll}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            </Col>
            <Col lg={4} xl={8} className="text-end">
              <PrimaryButton onClick={todayButtonHandler}>Today</PrimaryButton>
              <TodayDrawer open={open} onClose={() => setOpen(false)} />
            </Col>
          </Row>
        </Form>
      </Container>
      <StyledContainer className="section-box-shadow">
        <Table
          bordered={false}
          columns={columns}
          rowKey="id"
          loading={loading}
          dataSource={data ?? []}
          pagination={false}
          scroll={{
            scrollToFirstRowOnChange: false,
            x: 1000,
          }}
          sticky
        />
      </StyledContainer>
    </div>
  );
};

export default Calendar;

const StyledTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  height: 36px;
`;

const StyledContainer = styled(Container)`
  padding: 0;
  margin-top: 24px;

  .ant-table .ant-table-tbody tr td.date,
  .ant-table .ant-table-thead tr th.date {
    padding: 14px 0px;
    font-size: 12px;
    font-weight: 400;
  }

  .ant-table .ant-table-tbody tr td.employee-column,
  .ant-table .ant-table-thead tr th.employee-column {
    border-right: 1px solid ${COLORS.borderShadowColor};
    box-shadow: 6px 10px 16px 0px ${COLORS.borderShadowColor};
  }

  .ant-table .ant-table-tbody tr td {
    border-bottom: none;
  }

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none;
  }

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td:first-child {
    background: ${COLORS.white};
  }

  .ant-table .ant-table-thead tr th.date {
    vertical-align: bottom;
  }

  .ant-table .ant-table-thead tr th.date:has(.todayDay) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 0;
  }
`;

const StyledDatePicker = styled.div`
  display: flex;
  align-items: center;
  .ant-picker .ant-picker-input input {
    width: 100px;
    text-align: center;
    font-weight: 700;
  }
`;

const StyledLegend = styled.div`
  font-size: 14px;
  color: ${COLORS.inputFieldTextColor};
  display: flex;
  align-items: center;
  margin-left: 24px;
  &:before {
    content: "";
    width: 9px;
    height: 9px;
    margin-right: 9px;
  }
  &.holiday:before {
    background: ${COLORS.holidayBgColor};
  }
  &.otherleave:before {
    background: ${COLORS.leaveBgColor};
  }

  &.dayOff:before {
    background: ${COLORS.dayOffDateBg};
  }
`;

const StyledText = styled(Typography.Text)`
  font-size: 11px;
  color: ${COLORS.headerText};
`;

const StyledAvatar = styled(Avatar)`
  line-height: 59px !important;
  margin: 5px 0px;
  background-color: ${COLORS.disabledBgColor};
`;

const StyledUserName = styled(Typography.Title)`
  &.ant-typography {
    font-weight: 600;
    font-size: 14px;
  }
`;

const StyledIcon = styled(Icon)`
  &:focus-visible {
    outline: 1px solid ${COLORS.outlineColor};
    border-radius: 2px;
    outline-offset: 0px;
  }
`;

const StyledDayHeader = styled.span`
  .todayTitle {
    color: ${COLORS.inputFieldTextColor};
    font-size: 11px;
    margin-bottom: 4px;
    white-space: nowrap;
    position: relative;
    z-index: 1;
  }

  .todayDay {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    color: ${COLORS.white};
    font-weight: 500;
    background: ${COLORS.activeNavbar};
    border-radius: 100%;
    width: 22px;
    height: 22px;
    line-height: 0;
  }
`;
