import { useMutation, useQuery } from "@apollo/client";
import { Col, Empty, Image, Modal, Row, Typography } from "antd";
import { BreadCrumb } from "components/BreadCrumb";
import { BaseButton, DefaultButton, PrimaryButton } from "components/Buttons";
import { Container, LoadingPage } from "components/core";
import { ConfirmModal } from "components/modal";
import PERMISSION from "config/permission";
import COLORS from "constants/colors";
import { useAuthContext } from "contexts";
import { useFetchRequestList } from "contexts/LeaveRequestListProvider";
import LeaveForm from "features/users/LeaveForm";
import useCheckPermission from "hooks/useCheckPermission";
import useCreateLeaveRequest from "hooks/useCreateLeaveRequest";
import useFormModal from "hooks/useFormModal";
import { DestroyUserResponse, Employee, EmployeeId, FetchEmployeeResponse } from "model/Employee";
import { LeaveRequestArg } from "model/Leave";
import { isAdmin } from "model/User";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import employee from "routes/employee";
import { useNotify } from "services/api";
import { DESTROY_USER, FETCH_USER } from "services/graphql/employee";
import styled from "styled-components";
import backgroundImage from "assets/images/employee-bg-gradient.svg";
import imageDecoration from "assets/images/imageDecoration.svg";
import userFilledImage from "assets/images/userFilled.svg";
import Icon from "components/Icon";
import useLoginReminder from "hooks/useLoginReminder";
import dayjs from "dayjs";
import EmployeeDetailTabs from "./EmployeeDetailTabs";
import IsLeavingForm from "./IsLeavingForm";

type EmployeeFieldItemProps = {
  title: string;
  value?: string | null;
  canUpdate?: boolean;
};

const EmployeeFieldItem = ({ title, value, canUpdate }: EmployeeFieldItemProps) => (
  <StyleListItem className="d-flex">
    <StyledTitle>{title}</StyledTitle>
    <StyledData>
      {value || (
        <div>
          {canUpdate ? (
            <span className="message">Needs updating</span>
          ) : (
            <>
              <span className="message">Needs updating - Contact manager</span>
            </>
          )}
        </div>
      )}
    </StyledData>
  </StyleListItem>
);

const EmployeeDetail = () => {
  const { user } = useAuthContext();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const {
    showEmployeeEditPage,
    recordLeave,
    createLeavingRequest,
    employeeOverViewTab,
    employeeLeaveTab,
    deleteEmployeeDetail,
    rehireEmployeeDetail,
    employeeLeaveOverview,
    employeeLeaveTabTable,
    documentTab,
  } = PERMISSION;
  const {
    hasShowEmployeeEditPage,
    hasRecordLeave,
    hasCreateLeavingRequest,
    hasEmployeeOverViewTab,
    hasEmployeeLeaveTab,
    hasDeleteEmployeeDetail,
    hasEmployeeLeaveOverview,
    hasEmployeeLeaveTabTable,
    hasDocumentTab,
  } = useCheckPermission({
    showEmployeeEditPage,
    recordLeave,
    createLeavingRequest,
    employeeOverViewTab,
    employeeLeaveTab,
    deleteEmployeeDetail,
    rehireEmployeeDetail,
    employeeLeaveOverview,
    employeeLeaveTabTable,
    documentTab,
  });

  const { refetchList } = useFetchRequestList();

  const {
    isModalVisible: isLeavingModalVisible,
    showModal: showIsLeavingModal,
    hideModal: hideIsLeavingModal,
    onCloseModal: onCloseIsLeavingModal,
    onFieldsChange: onIsLeavingFieldsChange,
  } = useFormModal();

  const {
    isModalVisible: isRecordLeaveVisible,
    showModal: showRecordLeaveModal,
    hideModal: hideRecordLeaveModal,
    onCloseModal: onCloseRecordLeaveModal,
    onFieldsChange: onRecordLeaveFieldsChange,
  } = useFormModal();

  const {
    isModalVisible: isDestroyConfirmModalVisible,
    hideModal: hideDestroyConfirmModal,
    showModal: showDestroyConfirmModal,
  } = useFormModal();

  const { isModalVisible: isRehireModalVisible, hideModal: hideRehireModal } = useFormModal();
  const notify = useNotify();
  const { id } = useParams<{ id: EmployeeId }>();
  const userId = id || (user?.id as EmployeeId);
  const isMyProfile = userId === user?.id;
  const { loading, data, error } = useQuery<FetchEmployeeResponse>(FETCH_USER, {
    variables: {
      userIds: [userId],
      fetchBirthdaysThisWeek: false,
      fetchWorkAnniversaryThisWeek: false,
      showLeavers: state?.isLeaving,
    },
    skip: isMyProfile,
  });

  const userData = isMyProfile ? user : !loading ? data?.users?.nodes[0] : ({} as Employee);
  const { mutate, loading: recordLeaveLoading } = useCreateLeaveRequest(
    id,
    () => {
      hideRecordLeaveModal();
      refetchList(id);
    },
    "record",
  );

  const [destroyUser] = useMutation<DestroyUserResponse>(DESTROY_USER, {
    onCompleted: (response) => {
      if (response?.destroyUser?.success) {
        notify.success({ message: "Destroy employee successfully." });
        hideDestroyConfirmModal();
        navigate("/employees");
      }
    },
  });

  const [reminderMutate, { loading: notLoggedInLoading }] = useLoginReminder();

  const showEmployeeHasLeftLabel =
    state?.isLeaving &&
    dayjs()
      .startOf("day")
      .isAfter((userData as Employee).leavingRequest?.leavingAt);

  return (
    <div>
      <StyledDiv className="position-absolute top-0 start-0">
        {id ? (
          <BreadCrumb label="" onClick={() => (!state?.isLeaving ? navigate(-1) : navigate("/employees"))} />
        ) : (
          <Typography.Title level={1} className="mb-0">
            My profile
          </Typography.Title>
        )}
      </StyledDiv>
      {loading && <LoadingPage />}
      <Row className={`mt-5 section-box-shadow ${!id ? "pt-2" : ""}`}>
        <Col span={24}>
          <StyledContainer>
            {!state?.isLeaving && (
              <Row>
                {!isMyProfile && data?.users?.nodes[0] && !(userData as Employee).loggedIn && (
                  <StyledLoggedInMessage>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                      <Icon name="danger" />
                      <Typography.Text className="danger">This employee has not logged in</Typography.Text>
                    </div>
                    <BaseButton
                      className="errorMessageBtn"
                      loading={notLoggedInLoading}
                      onClick={() => reminderMutate({ variables: { userId: id } })}
                    >
                      Send reminder email
                    </BaseButton>
                  </StyledLoggedInMessage>
                )}
                <Row className="gap-2 ms-auto">
                  {(hasShowEmployeeEditPage || !id) && (
                    <DefaultButton
                      onClick={() => {
                        if (id && !Number.isNaN(+id)) {
                          navigate(employee.edit(id));
                        } else {
                          navigate(employee.myProfileEdit());
                        }
                      }}
                    >
                      Edit
                    </DefaultButton>
                  )}
                  {id && !Number.isNaN(+id) && (
                    <>
                      {hasCreateLeavingRequest && (
                        <DefaultButton onClick={showIsLeavingModal}>This employee is leaving</DefaultButton>
                      )}
                      {hasRecordLeave && <DefaultButton onClick={showRecordLeaveModal}>Record leave</DefaultButton>}
                    </>
                  )}
                </Row>
              </Row>
            )}
            {state?.isLeaving && (
              <Row className="justify-content-end gap-2 mb-3">
                {hasDeleteEmployeeDetail && (
                  <DefaultButton onClick={showDestroyConfirmModal}>Delete employee information forever</DefaultButton>
                )}
              </Row>
            )}
            {userData && (
              <StyledDetailContainer>
                <Col>
                  <Image
                    rootClassName="position-absolute bottom-0"
                    src={imageDecoration}
                    preview={false}
                    alt="decoration"
                  />
                  <div>
                    {showEmployeeHasLeftLabel && (
                      <StyledEmployeeLeft className="rounded-top">
                        <Typography.Title level={4}>This employee has left</Typography.Title>
                      </StyledEmployeeLeft>
                    )}
                    <Image
                      className={`${state?.isLeaving ? "rounded-bottom" : "rounded"}`}
                      src={userData?.profileImageUrl ?? userFilledImage}
                      width={248}
                      height={249}
                      rootClassName="ms-3 mb-3"
                      preview={false}
                      alt="userProfile"
                    />
                  </div>
                </Col>
                <Col className="d-flex flex-column justify-content-between">
                  <Row className="flex-column">
                    <Typography.Title className="m-0">{userData?.name}</Typography.Title>
                    <StyledSubTitle className="text-uppercase">
                      Employee number: {userData?.employeeNumber || "-"}
                    </StyledSubTitle>
                  </Row>
                  <Row className="flex-column mb-1">
                    <EmployeeFieldItem title="Email" value={userData?.email} canUpdate={isAdmin(user)} />
                    <EmployeeFieldItem title="Work number" value={userData?.phone} canUpdate={isAdmin(user)} />
                    <EmployeeFieldItem title="Job title" value={userData?.jobTitle?.name} canUpdate={isAdmin(user)} />
                    <EmployeeFieldItem
                      title="Department"
                      value={userData?.departments && userData.departments.map((department) => department.name).join()}
                      canUpdate={isAdmin(user)}
                    />
                    <EmployeeFieldItem
                      title="Line manager"
                      value={
                        userData?.lineManagers && userData.lineManagers.map((lineManager) => lineManager.name).join()
                      }
                      canUpdate={isAdmin(user)}
                    />
                  </Row>
                </Col>
              </StyledDetailContainer>
            )}
          </StyledContainer>
        </Col>
      </Row>

      {(hasEmployeeOverViewTab ||
        hasEmployeeLeaveTab ||
        hasEmployeeLeaveOverview ||
        hasEmployeeLeaveTabTable ||
        hasDocumentTab ||
        isMyProfile) && <EmployeeDetailTabs />}
      {(error || !userId) && (
        <Row className="mt-5">
          <Col span={24}>
            <StyledContainer>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Not valid employee." />
            </StyledContainer>
          </Col>
        </Row>
      )}

      <Modal
        title="This employee is leaving"
        open={isLeavingModalVisible}
        onCancel={onCloseIsLeavingModal}
        footer={null}
        width={430}
        destroyOnClose
        centered
      >
        <IsLeavingForm
          onFieldsChange={onIsLeavingFieldsChange}
          name={userData?.name}
          id={userData?.id}
          hideIsLeavingModal={hideIsLeavingModal}
          employeeIsLeaving={() => {
            navigate(pathname, { state: { isLeaving: true } });
          }}
        />
      </Modal>

      <Modal
        width={385}
        title="Record leave"
        open={isRecordLeaveVisible}
        footer={null}
        onCancel={onCloseRecordLeaveModal}
        destroyOnClose
        centered
      >
        <LeaveForm
          leaveFormType="record"
          onFieldsChange={onRecordLeaveFieldsChange}
          onSubmit={(leaveRequest: LeaveRequestArg) => mutate({ variables: { ...leaveRequest, userId: id } })}
          loading={recordLeaveLoading}
        />
      </Modal>

      <ConfirmModal
        onCancel={hideDestroyConfirmModal}
        open={isDestroyConfirmModalVisible}
        onOk={() => {
          if (hasDeleteEmployeeDetail) {
            destroyUser({ variables: { id } });
          }
        }}
        width={310}
        title="Are you sure you want to delete employee information forever?"
        okText="Yes"
        cancelText="No"
      />
      <Modal
        width={385}
        title="Rehire Employee"
        open={isRehireModalVisible}
        footer={null}
        onCancel={hideRehireModal}
        destroyOnClose
        centered
      >
        <>
          <PrimaryButton className="w-100 mb-3">Ignore “Leaving request”</PrimaryButton>
          <PrimaryButton className="w-100">Rehire employee with new contract details</PrimaryButton>
        </>
      </Modal>
    </div>
  );
};

export default EmployeeDetail;

const StyledDiv = styled.div`
  background-image: url(${backgroundImage});
  width: 100%;
  height: 130px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 28px 36px;
`;

const StyledContainer = styled(Container)`
  padding: 20px 20px 32px 37px;
`;

const StyledSubTitle = styled(Typography.Text)`
  color: ${COLORS.headerText};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
`;

const StyleListItem = styled.span`
  width: 100%;
  padding: 2px 0;
  gap: 4%;

  span.message {
    padding: 2px 8px;
    height: 30px;
    width: fit-content;
    background: ${COLORS.errorTextBgColor};
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
    color: ${COLORS.errorTextColor};
    position: relative;
    display: flex;
    align-items: center;
  }
`;

const StyledTitle = styled.span`
  width: 140px;
  height: 30px;
  line-height: 20px;
  color: ${COLORS.inputFieldTextColor};
  display: flex;
  align-items: center;
`;

const StyledData = styled(StyledTitle)`
  font-weight: 600;
  color: ${COLORS.primaryColor};
  width: 100%;
`;

const StyledDetailContainer = styled(Row)`
  display: grid;
  grid-template-columns: 265px 1fr;
  gap: 50px;
`;

const StyledEmployeeLeft = styled.div`
  height: 36px;
  width: 248px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.warningColor};
  position: absolute;
  z-index: 1;

  & .ant-typography {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0px;
    color: ${COLORS.headerTextBold};
  }
`;

const StyledLoggedInMessage = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 3px 3px 14px;
  background-color: ${COLORS.errorTextBgColor};
  margin-left: 14px;
  margin-bottom: 20px;
  border-radius: 4px;
  height: 36px;
  max-width: 500px;
  width: 53.2%;

  .danger {
    font-weight: 600;
    font-size: 13px;
    color: ${COLORS.loggedMessageColor};
  }

  & .errorMessageBtn {
    border: 1px solid ${COLORS.loggedInBorderColor} !important;
    height: 30px;

    :hover,
    :focus-within {
      border-color: ${COLORS.disabledColor} !important;
    }
  }
`;
