import { Modal, Table, Typography } from "antd";
import { ColumnsType, SorterResult } from "antd/lib/table/interface";
import { DefaultButton } from "components/Buttons";
import DetailModal, { DetailItemProps } from "components/modal/DetailModal";
import DATE_FORMAT from "constants/dateFormat";
import TABLE from "constants/table";
import { useAuthContext } from "contexts";
import { useFetchRequestList } from "contexts/LeaveRequestListProvider";
import { useNotificationContext } from "contexts/NotificationProvider";
import dayjs from "dayjs";
import useFormModal from "hooks/useFormModal";
import {
  BookingStatus,
  EnhancedLeaveRequest,
  LeaveRequestsReason,
  LeaveRequestsStatus,
  LeaveRequestsStatusLabels,
  leaveTypeLabels,
  bookingStatusLabel,
  DestroyLeaveRequestResponse,
  LeaveRequestsDayCompleteness,
} from "model/Leave";
import { useState, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { expectNumber } from "utils";
import { tableColumnCommonData, sortingTableData, SortTypeEnum, SortOrderEnum } from "components/Table";
import { isManager } from "model/User";
import { ConfirmModal } from "components/modal";
import { useMutation } from "@apollo/client";
import { DESTROY_LEAVE_REQUEST, FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE } from "services/graphql/leave";
import { useNotify } from "services/api";
import TIME_FORMAT from "constants/timeFormat";
import CancelOrAmendForm from "./CancelOrAmendForm";

export const mapLeaveRequestToFullDetail = (record: EnhancedLeaveRequest) => [
  {
    label: "Leave type",
    value: leaveTypeLabels[record.reason],
  },
  {
    label: "Start day",
    value: dayjs(record.fromDate).format(DATE_FORMAT.fullDate),
  },
  {
    label: "End day",
    value: dayjs(record.toDate).format(DATE_FORMAT.fullDate),
  },
  ...(record.dayCompleteness === LeaveRequestsDayCompleteness.Custom
    ? [
        {
          label: "Start time",
          value: dayjs(record.fromTime, TIME_FORMAT.fullTimeFormat).format(TIME_FORMAT.timeWithPeriodTertiary),
        },
        {
          label: "End time",
          value: dayjs(record.toTime, TIME_FORMAT.fullTimeFormat).format(TIME_FORMAT.timeWithPeriodTertiary),
        },
      ]
    : []),
  {
    label: "Total days",
    value: `${(record?.usedDays ?? 0).toFixed(2)} ${
      record.dayCompleteness === LeaveRequestsDayCompleteness.HalfInMorning ||
      record.dayCompleteness === LeaveRequestsDayCompleteness.HalfInAfternoon
        ? record.dayCompleteness === LeaveRequestsDayCompleteness.HalfInMorning
          ? "(AM)"
          : "(PM)"
        : ""
    }`,
  },
  {
    label: "Number of remaining days leave prior to request",
    value:
      record.reason === LeaveRequestsReason.Holiday
        ? ((record.accumulativeBalance ?? 0) - (record.precedingUsedHolidayDays ?? 0)).toFixed(2)
        : "",
  },
  {
    label: "Number of remaining days leave following request",
    value:
      record.reason === LeaveRequestsReason.Holiday
        ? ((record.accumulativeBalance ?? 0) - (record.precedingUsedHolidayDays ?? 0) - (record.usedDays ?? 0)).toFixed(
            2,
          )
        : "",
  },
  {
    label: "Employee notes",
    value: record.employeeNote?.body,
  },
  {
    label: "Manager notes",
    value: record.lineManagerNote?.body,
  },
  {
    label: "Attachments",
    value: record?.attachmentUrl,
  },
];

const LeaveDetailTable = () => {
  const { user } = useAuthContext();
  const { refresh, refetchMyNotificationLeave } = useNotificationContext();

  const { id } = useParams();
  const userId = id || user?.id;
  const isMyProfile = userId === user?.id;
  const { data, loading, refetchList } = useFetchRequestList();
  const notify = useNotify();
  const { isModalVisible, hideModal, showModal } = useFormModal();
  const {
    isModalVisible: isLeaveCancelModalVisible,
    showModal: showLeaveCancelModal,
    hideModal: hideLeaveCancelModal,
    onCloseModal: onCloseLeaveCancelModal,
    onFieldsChange: onLeaveCancelFieldsChange,
  } = useFormModal();

  const {
    isModalVisible: isDestroyConfirmModalVisible,
    hideModal: hideDestroyConfirmModal,
    showModal: showDestroyConfirmModal,
  } = useFormModal();

  const [notificationId, setNotificationId] = useState<number>();
  const [sortedInfo, setSortedInfo] = useState<SorterResult<EnhancedLeaveRequest>>({});
  const [fullDetail, setFullDetail] = useState<DetailItemProps[]>([]);
  const [getRecord, setRecord] = useState<EnhancedLeaveRequest>();

  const getRequestFullDetail = (record: EnhancedLeaveRequest) => {
    setFullDetail(mapLeaveRequestToFullDetail(record));
  };

  const [destroyLeaveRequest] = useMutation<DestroyLeaveRequestResponse>(DESTROY_LEAVE_REQUEST, {
    onCompleted: (response) => {
      if (response?.destroyLeaveRequest?.success) {
        notify.success({ message: "Cancel leave request successfully." });
        hideDestroyConfirmModal();
        refetchList(id);
        if (isMyProfile && notificationId) {
          refresh();
          refetchMyNotificationLeave();
        }
      }
    },
    refetchQueries: [{ query: FETCH_USAGE_STATS_AND_HOLIDAY_BALANCE, variables: { userId: id } }],
  });

  const actionHandler = (record: EnhancedLeaveRequest) => {
    if (isMyProfile) {
      const idOfNotifications = expectNumber(`${user?.id}${record.id}`);
      setNotificationId(idOfNotifications);
    }
    setRecord(record);
  };

  const columns: ColumnsType<EnhancedLeaveRequest> = [
    {
      ...tableColumnCommonData({
        title: "Number",
        dataIndex: "id",
        defaultSortOrder: SortOrderEnum.Descend,
        width: 100,
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Number, firstData: a.id, secondData: b.id }),
      render: (_, { id }) => <span>{id}.</span>,
    },
    {
      ...tableColumnCommonData({
        title: "Leave type",
        dataIndex: "reason",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { reason }) => <span>{leaveTypeLabels[reason]}</span>,
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.String,
          firstData: leaveTypeLabels[a.reason],
          secondData: leaveTypeLabels[b.reason],
        }),
    },
    {
      ...tableColumnCommonData({
        title: "Date started",
        dataIndex: "fromDate",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { fromDate }) => <span>{fromDate ? dayjs(fromDate).format(DATE_FORMAT.fullDateDefault) : "-"}</span>,
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.fromDate, secondData: b.fromDate }),
    },
    {
      ...tableColumnCommonData({
        title: "Date finished",
        dataIndex: "toDate",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { toDate }) => <span>{toDate ? dayjs(toDate).format(DATE_FORMAT.fullDateDefault) : "-"}</span>,
      sorter: (a, b) => sortingTableData({ type: SortTypeEnum.Date, firstData: a.toDate, secondData: b.toDate }),
    },
    {
      ...tableColumnCommonData({
        title: "Approval status",
        dataIndex: "status",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { status }) => <span>{LeaveRequestsStatusLabels[status]}</span>,
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.String,
          firstData: LeaveRequestsStatusLabels[a.status],
          secondData: LeaveRequestsStatusLabels[b.status],
        }),
    },
    {
      ...tableColumnCommonData({
        title: "Status",
        dataIndex: "bookingStatus",
        sortInfo: { sortedColumn: sortedInfo.columnKey, sortOrder: sortedInfo.order },
      }),
      render: (_, { bookingStatus }) => <span>{bookingStatus ? `${bookingStatusLabel[bookingStatus]}` : "-"}</span>,
      sorter: (a, b) =>
        sortingTableData({
          type: SortTypeEnum.String,
          firstData: a.bookingStatus ? bookingStatusLabel[a.bookingStatus] : "-",
          secondData: b.bookingStatus ? bookingStatusLabel[b.bookingStatus] : "-",
        }),
    },
    {
      title: "Details",
      dataIndex: "Details",
      align: "center",
      width: 90,
      render: (_, record) => (
        <DefaultButton
          onClick={() => {
            getRequestFullDetail(record);
            showModal();
          }}
        >
          Details
        </DefaultButton>
      ),
    },
    {
      title: "",
      dataIndex: "Cancel",
      align: "right",
      width: 160,
      render: (_, record) =>
        (record.bookingStatus === BookingStatus.Booked || !record.bookingStatus) &&
        record.status !== LeaveRequestsStatus.Declined ? (
          <DefaultButton
            onClick={() => {
              actionHandler(record);
              showLeaveCancelModal();
            }}
            className="me-2"
          >
            Cancel/Amend
          </DefaultButton>
        ) : !isManager(user) && id && record.status !== LeaveRequestsStatus.Declined ? (
          <DefaultButton
            icon="delete"
            data-testid="destroyLeave"
            className="me-2"
            onClick={() => {
              actionHandler(record);
              showDestroyConfirmModal();
            }}
          />
        ) : (
          <></>
        ),
    },
  ];

  useLayoutEffect(() => {
    refetchList(userId);
  }, [refetchList, userId]);

  return (
    <StyledWrapper className="section-box-shadow">
      <Table
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={false}
        rowKey="id"
        title={() => (
          <Typography.Title level={3} className="m-0 p-2">
            Holidays and absences taken
          </Typography.Title>
        )}
        scroll={{
          scrollToFirstRowOnChange: false,
          y: TABLE.tableDefaultHeight,
        }}
        sortDirections={TABLE.tableSortDirections}
        onChange={(_pagination, _filter, sorter) => setSortedInfo(sorter as SorterResult<EnhancedLeaveRequest>)}
      />

      <DetailModal
        title="Details"
        open={isModalVisible}
        onCancel={hideModal}
        width={519}
        data={fullDetail}
        destroyOnClose
      />

      <Modal
        title="Cancel / Amend request"
        open={isLeaveCancelModalVisible}
        onCancel={onCloseLeaveCancelModal}
        footer={null}
        width={430}
        destroyOnClose
        centered
      >
        <CancelOrAmendForm
          removeNotification={() => {
            if (isMyProfile && notificationId) {
              refresh();
            }
          }}
          onFieldsChange={onLeaveCancelFieldsChange}
          data={getRecord}
          hideModal={hideLeaveCancelModal}
        />
      </Modal>
      <ConfirmModal
        onCancel={hideDestroyConfirmModal}
        open={isDestroyConfirmModalVisible}
        onOk={() => {
          destroyLeaveRequest({ variables: { id: getRecord?.id } });
        }}
        width={310}
        title="Are you sure you want to delete this leave?"
        okText="Yes"
        cancelText="No"
      />
    </StyledWrapper>
  );
};

export default LeaveDetailTable;

const StyledWrapper = styled.div`
  .ant-table-column-sorter {
    display: none;
  }
`;
