import { useLazyQuery } from "@apollo/client";
import { useSafeContext } from "hooks";
import { FetchUserLeaveListResponse, EnhancedLeaveRequest } from "model/Leave";
import React, { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { FETCH_LEAVE_REQUESTS_USER_ID } from "services/graphql/leave";
import { expectNumber } from "utils";
import PERMISSION from "config/permission";
import useCheckPermission from "hooks/useCheckPermission";
import TABLE from "constants/table";
import { useAuthContext } from "./AuthProvider";

interface ContextValue {
  loading: boolean;
  data: EnhancedLeaveRequest[];
  setData: React.Dispatch<React.SetStateAction<EnhancedLeaveRequest[]>>;
  refetchList: (id?: string) => void;
}

interface FetchPageDataArg {
  id?: string;
  accumulated?: EnhancedLeaveRequest[] | null;
  cursor?: string | null;
}

const LeaveRequestListContext = createContext<ContextValue | null>(null);
LeaveRequestListContext.displayName = "FetchLeaveRequestList";

export const useFetchRequestList = () => useSafeContext(LeaveRequestListContext);

export const LeaveRequestListProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<EnhancedLeaveRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuthContext();
  const { companies } = PERMISSION;
  const { hasCompanies } = useCheckPermission({
    companies,
  });

  const [fetch] = useLazyQuery<FetchUserLeaveListResponse>(FETCH_LEAVE_REQUESTS_USER_ID, { fetchPolicy: "no-cache" });

  const fetchPageData = useCallback(
    async ({ id, accumulated, cursor }: FetchPageDataArg) => {
      const userId = id || user?.id;
      const result = await fetch({
        variables: {
          first: TABLE.maxRowPerPage,
          userId,
          after: cursor || undefined,
        },
      });

      if (result.data) {
        const { nodes, pageInfo } = result.data.leaveRequests;
        const newData = [...(accumulated ?? []), ...nodes];

        if (pageInfo.hasNextPage) {
          return fetchPageData({ id: userId, accumulated: newData, cursor: pageInfo.endCursor });
        }

        return newData;
      }
      return [];
    },
    [fetch, user?.id],
  );

  const refetchList = useCallback(
    async (id?: string) => {
      if (!hasCompanies && user?.subscriptionActive) {
        setLoading(true);
        const allFetchedData = await fetchPageData({ id });
        setData(allFetchedData);
        setLoading(false);
      }
    },
    [fetchPageData, hasCompanies, user?.subscriptionActive],
  );

  const value = useMemo(
    () => ({
      data: data.sort((a, b) => expectNumber(a.id) - expectNumber(b.id)),
      refetchList,
      loading,
      setData,
    }),
    [data, refetchList, loading],
  );

  return <LeaveRequestListContext.Provider value={value}>{children}</LeaveRequestListContext.Provider>;
};
